<template>

  <v-row>

    <v-col v-if="shopData" class="col-md-3">

      <v-list>
        <v-list-item
            v-for="(data) in shopData"
            :key="data.iso"
            @click.stop="setCustomers(data.customers)">
          <v-list-item-icon>
            <v-img
                :src="flag(data.iso)"
                class="country-icon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ data.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ data.customers.length }} shops</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-col>

    <v-col v-if="customers">

      <v-card-title>
        <v-text-field v-model="search" label="Zoeken"></v-text-field>
      </v-card-title>

      <v-data-table
          :footer-props="tableFooterProps"
          :headers="headers"
          :items="customers"
          :search="search"
      >
        <template v-slot:item.id="{ item }">
          <v-btn
              :to="{ name: 'SaleCustomer', params: { customerId: item.id, customer: item.name} }"
              outlined
              rounded
              small
          >
            Details
          </v-btn>
        </template>
      </v-data-table>

    </v-col>

    <v-col v-if="!shopData" class="text-center">

      <v-progress-circular color="accent" indeterminate></v-progress-circular>
      <v-card-text> De gevraagde inhoud wordt ingeladen.</v-card-text>

    </v-col>

  </v-row>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ShopsPage',

  data: function () {
    return {
      tab: null,
      search: null,
      customers: null,
      tableFooterProps: {
        'items-per-page-options': [25, 50, 75, -1],
      },
      headers: [
        {
          text: 'Naam',
          value: 'name',
        },
        {
          text: 'Prijs beschrijving',
          value: 'priceDescription',
        },
        {
          text: '',
          value: 'id',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      shopData: 'Shops/shops',
    }),
  },

  created() {
    this.fetch();
  },

  methods: {
    ...mapActions({
      fetch: 'Shops/fetchAllShopsAsync',
    }),

    flag(iso) {
      try {
        return require('@/assets/flags/' + iso.toUpperCase() + '.png');
      } catch {
        return '';
      }
    },

    setCustomers(data) {
      this.customers = data;
      this.search = null;
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped>
.country-icon {
  border: 1px solid #cccccc;
}
</style>
